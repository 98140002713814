import React from "react";
import JobApplyForm from "../../components/global-components/apply-form";

const JobApply = () => {
  return (
    <div>
      <JobApplyForm customclass="pd-top-190" />
    </div>
  );
};

export default JobApply;
