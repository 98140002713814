import React from 'react';
import ComingSoonPage from './components/coming-soon-page';

const ComingSoon = () => {
    return <div>
        <ComingSoonPage />
    </div>
}

export default ComingSoon

